<template>
  <schedule />
</template>

<script>
import schedule from "./components/schedule.vue";

export default {
  components: {
    schedule,
  },
};
</script>
