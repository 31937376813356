<template>
  <div class="filters">
    <div class="filter_box">
      <h4>Выберите отделение</h4>
      <select v-model="selectedDepartment" :disabled="searchField != ''">
        <option
          v-for="(dep, index) in shedule"
          :key="index"
          v-bind:value="dep.name"
          :selected="shedule[0].name"
        >
          {{ dep.name }}
        </option>
      </select>
    </div>
    <div class="filter_box">
      <h4>Поиск по ФИО или должности</h4>
      <div class="form-group">
        <input type="text" placeholder="Начните набирать текст" v-model="searchField" />
      </div>
    </div>
  </div>
  <div class="filter_reset"><button @click="resetFilter">Сбросить фильтр</button></div>

  <circles-to-rhombuses-spinner
    :animation-duration="1000"
    :size="40"
    style="margin-top: 25px"
    color="#007d35"
    v-if="!show"
  />
  <div v-if="searchField" class="department">
    <div class="slider_box" v-for="(spec, index) in filteredList" :key="index">
      <div class="slider_block">
        <div class="doctor">
          <h3>{{ spec.name }}</h3>
          {{ spec.job }}
        </div>
        <div class="cabinet">{{ spec.dates[0].schedules[0].room }}<br /></div>
        <div class="shedule">
          <div
            class="shedule-item"
            v-for="(date, index) in spec.dates.sort((a, b) => (a.date > b.date ? 1 : -1))"
            :key="index"
          >
            <div class="shedule-item-date">
              {{ getFilterDate(date.schedules[0].visit) }}
              <span>{{ getFilterDateToDay(date.schedules[0].visit) }}</span>
            </div>
            {{ getFilterDateToTime(date.schedules[0].visit) }}
            -
            {{ getFilterDateToTime(date.schedules[date.schedules.length - 1].visit) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="department" v-for="(dep, index) in filteredList" :key="index">
    <div class="slider_box" v-for="(spec, index) in dep.completedLines" :key="index">
      <div class="slider_block">
        <div class="doctor">
          <h3>{{ spec.name }}</h3>
          {{ spec.job }}
        </div>
        <div class="cabinet">{{ spec.dates[0].schedules[0].room }}<br /></div>
        <div class="shedule">
          <div
            class="shedule-item"
            v-for="(date, index) in spec.dates.sort((a, b) => (a.date > b.date ? 1 : -1))"
            :key="index"
          >
            <div class="shedule-item-date">
              {{ getFilterDate(date.schedules[0].visit) }}
              <span>{{ getFilterDateToDay(date.schedules[0].visit) }}</span>
              <!-- {{ date.schedules[0].appointment }}
                {{ date.schedules[0].slot }} -->
            </div>
            {{
              getTimeLastSlot(
                date.schedules[0].visit,
                date.schedules[date.schedules.length - 1].visit
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
moment.locale("ru");
import { CirclesToRhombusesSpinner } from "epic-spinners";

export default {
  name: "App",
  data() {
    return {
      shedule: [],
      allDoctors: [],
      selectedDepartment: [],
      show: false,
      searchField: "",
      config: window.VUE_APP_MO_OID,
      moOut: window.VUE_APP_MO_OUT,
    };
  },
  components: {
    CirclesToRhombusesSpinner,
  },
  computed: {
    filteredList() {
      if (this.searchField == "") {
        return this.shedule.filter((content) => {
          const c = [content].find((e) => e.name === this.selectedDepartment);
          return c;
          //   return content.name.includes(this.selectedDepartment);
        });
      } else {
        return this.allDoctors.filter((content) => {
          return (
            content.name.toLowerCase().includes(this.searchField.toLowerCase()) ||
            content.job.toLowerCase().includes(this.searchField.toLowerCase())
          );
        });
      }
    },
  },
  methods: {
    getFilterDate: function (date) {
      return moment(date, "YYYY-MM-DDTHH:mm").format("DD.MM");
    },
    getFilterDateToTime: function (date) {
      return moment(date, "YYYY-MM-DDTHH:mm").format("HH:mm");
    },
    getTimeLastSlot: function (t1, t2) {
      let time1 = moment(t1, "YYYY-MM-DDTHH:mm").format("HH:mm");
      let time2 = moment(t2, "YYYY-MM-DDTHH:mm").format("HH:mm");

      const objs = [time1, time2];

      function compare(time1, getTime) {
        if (time1 < getTime) {
          return -1;
        }
        if (time1 > getTime) {
          return 1;
        }
        return 0;
      }

      objs.sort(compare);

      if (objs[0] == objs[1]) {
        return `${objs[0]}`;
      } else {
        return `${objs[0]} - ${objs[1]}`;
      }
    },
    getFilterDateToDay: function (date) {
      return moment(date, "YYYY-MM-DDTHH:mm").format("dddd");
    },
    async userLogin() {
      await axios
        .post("https://shedule-server.dz72.ru/api/auth/signin", {
          username: "user",
          password: this.password,
        })
        .then((response) => {
          let parsed = JSON.stringify(response.data.accessToken);
          localStorage.setItem("x-access-token", parsed);
          this.dataShedule();
        })
        .catch((error) => {
          console.log(error.message);
          localStorage.removeItem("x-access-token");
        });
    },

    async dataShedule() {
      let headers = {
        "x-access-token": JSON.parse(localStorage.getItem("x-access-token")),
      };
      const oidMO = this.config;
      const url = `https://shedule-server.dz72.ru/api/data/${oidMO}`;

      await axios
        .get(url, { headers })
        .then((response) => {
          let shedule = response.data[0].departments;

          const moOut = shedule.filter(
            (item) => !window.VUE_APP_MO_OUT.includes(item.name)
          );

          const moOutNoSpec = moOut.flatMap(({ name, specialists }) => {
            const completedLines = specialists.filter(
              (x) => !window.VUE_APP_SPEC_OUT.includes(x.job)
            );
            if (completedLines.length === 0) {
              return [];
            } else {
              return [{ name, completedLines }];
            }
          });

          this.shedule = moOutNoSpec.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });

          this.selectedDepartment = moOutNoSpec[0].name;

          this.show = true;

          moOutNoSpec.forEach((el) => {
            el.completedLines.forEach((el) => {
              this.allDoctors.push(el);
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    encrypt() {
      const encryptedP = this.$CryptoJS.AES.encrypt(
        process.env.VUE_APP_PASS,
        process.env.VUE_APP_PASS_SECRET
      ).toString();

      this.password = encryptedP;
    },
    resetFilter() {
      this.searchField = "";
    },
  },
  beforeMount() {},
  mounted() {
    this.encrypt();
    this.userLogin();
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html,
body {
  font: 15px/25px "PT Sans Caption", Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  min-height: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app h4 {
  margin-top: 0;
  margin-bottom: 6px;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
}

#app select {
  width: 360px;
  height: 42px;
  border-radius: 6px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  margin-bottom: 16px;
}

.filters {
  display: flex;
  gap: 60px;
}

#app input {
  width: 360px;
  height: 42px;
  border-radius: 6px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  margin-bottom: 16px;
}

.filter_reset button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  line-height: 110%;
  padding: 8px;
  border: 1px solid #656565;
  border-radius: 6px;
  background: #656565;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  transition: 0.3s;
}

.filter_reset button:hover {
  border: 1px solid #000000;
  background: #000000;
}

.department {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.slider_box {
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  width: 100%;
  display: flex;
}

.shedule-item {
  text-align: center;
}

.slider_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.slider_block .doctor {
  min-width: 50%;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.slider_block .doctor h3 {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

.slider_block .cabinet {
  min-width: 50%;
  max-width: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
}

.slider_block .shedule {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.shedule-item-date {
  display: flex;
  flex-direction: column;
  line-height: 110%;
  padding: 8px;
  border: 1px solid #175e36;
  border-radius: 6px;
  background: #007d35;
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100px;
}

@media (max-width: 768px) {
  #app select {
    width: 100%;
  }

  #app input {
    width: 100%;
  }

  .filters {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  .slider_block .shedule {
    width: 100%;
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .slider_block {
    flex-direction: column;
  }
}
</style>
